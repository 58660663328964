@import './var.scss';

.contact-container {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;

  .contact-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
    }
  }

  .contact-content {
    .contact-form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        font-weight: bold;
        color: $dark-grey;
      }

      input,
      textarea {
        margin-bottom: 20px;
        padding: 10px;
        font-size: 1em;
        border: 1px solid $dark-grey;
        border-radius: 5px;
      }

      button {
        background-color: $primary-blue;
        color: $white-color;
        font-size: 1em;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($primary-blue, 10%);
        }
      }
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .contact-container {
    padding: 20px;
  }

  .contact-header {
    h1 {
      font-size: 2em;
    }
  }

  .contact-content {
    .contact-form {
      label {
        font-size: 1em;
      }

      input,
      textarea {
        font-size: 1em;
      }

      button {
        font-size: 1em;
      }
    }
  }
}
