@import './var.scss';

.about-container {
  padding: 40px;
  max-width: 1200px; // Adjusted to allow space for the image
  margin: 0 auto;
  text-align: left;

  .about-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
    }
  }

  .about-content {
    display: flex;
    flex-direction: column; // Stack elements vertically by default
    align-items: center;
    gap: 20px;

    .about-text {
      flex: 1;

      p {
        font-size: 1.2em;
        line-height: 1.6;
        color: $black-color;
        margin-bottom: 20px;
      }
    }

    .about-image {
      flex: 1;
      width: 100%; // Ensure the image takes full width in its container
      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .about-certifications {
    text-align: center;
    margin-top: 40px;

    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 20px;
    }

    .cert-logos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      img {
        width: 150px;
        height: auto;
        border-radius: 10px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .about-content {
      flex-direction: row-reverse; 
      .about-image {
        margin-bottom: 0; 
      }
    }
  }
}
