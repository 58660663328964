// Variables
$primary-blue: #4A90E2; // Matches the blue text in the logo
$secondary-green: #50E3C2; // Matches the globe in the logo
$dark-grey: #424242; // Dark grey for the navbar
$white-color: #ffffff;
$black-color: #000000;

// Reset styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// Set up the page container
body {
  background-color: $white-color;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

// Style the App component
.App {
  text-align: center;
}

.App-Logo {
  width: 15%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  background-color: $white-color;
}

.App-Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white-color;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

// Navigation bar styles
.nav-wrapper {
  background-color: $dark-grey;
  width: 100%;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    a, span {
      color: $white-color;
      text-decoration: none;
      padding: 10px 20px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $primary-blue;
      }

      &.active {
        text-decoration: underline;
        color: $primary-blue;
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: $dark-grey;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        min-width: 200px; // Added min-width to prevent content overflow

        a {
          color: $white-color;
          padding: 10px 20px;
          display: block;

          &:hover {
            background-color: $primary-blue;
          }
        }
      }

      &:hover .dropdown-content, &.dropdown-open .dropdown-content {
        display: block;
      }
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .nav-wrapper nav {
    flex-direction: column;

    .dropdown .dropdown-content {
      position: static;
    }
  }
}


// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .App-Logo {
    width: 30%; // Adjust logo size for mobile
  }

  nav {
    flex-direction: column;
    
    a {
      margin: 10px 0; // Adjust spacing between buttons for mobile
    }

    .dropdown .dropdown-content {
      position: static;
      box-shadow: none;
    }
  }
}

// Style the footer
footer p {
  display: flex;
  justify-content: center;
}
