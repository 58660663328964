@import './var.scss';

.footer-container {
  background-color: $dark-grey;
  color: $white-color;
  padding: 20px 0;
  text-align: center;

  .footer-content {
    max-width: 800px;
    margin: 0 auto;

    p {
      font-size: 1em;
      margin: 0;
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .footer-container {
    padding: 10px 0;

    .footer-content {
      p {
        font-size: 0.9em;
      }
    }
  }
}
