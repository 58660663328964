// itSupport.scss
@import './var.scss';

.itsupport-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  .itsupport-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
    }
  }

  .itsupport-section {
    display: flex;
    flex-direction: column; // Change to column for mobile

    .itsupport-text {
      padding: 0 20px;
      text-align: center;
      margin-bottom: 20px;

      h2 {
        font-size: 2em;
        color: $primary-blue;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        color: $black-color;
        line-height: 1.6;
      }
    }

    .itsupport-image {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 80%;
        max-width: 300px; // Adjust max-width as needed
        display: block;
        margin: 0 auto; // Center the image
        border-radius: 10px;
      }
    }
  }

  .hardware-repair {
    flex-direction: column;

    .itsupport-text {
      padding: 0 20px;
      text-align: center;
      margin-bottom: 20px;
    }

    .itsupport-image {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 80%;
        max-width: 300px; // Adjust max-width as needed
        display: block;
        margin: 0 auto; // Center the image
        border-radius: 10px;
      }
    }
  }
}

// Media queries for tablet and desktop responsiveness
@media (min-width: 769px) {
  .hardware-repair {
    flex-direction: row; // Display as row for larger screens

    .itsupport-text {
      flex: 1;
      padding: 0 20px;
      text-align: left;
      margin-bottom: 0;

      h2 {
        font-size: 2em;
        color: $primary-blue;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        color: $black-color;
        line-height: 1.6;
      }
    }

    .itsupport-image {
      flex: 1;
      text-align: right;

      img {
        width: 300px; // Set the width for square shape
        height: auto; // Ensure the height adjusts accordingly
        border-radius: 10px;
      }
    }
  }
}




