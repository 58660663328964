@import './var.scss';

.securityconsulting-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  .securityconsulting-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
    }
  }

  .securityconsulting-content {
    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2em;
      color: $black-color;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }
}
