@import './var.scss';

.home-container {
  .home-header {
    img {
      width: 100%;
      height:100%;
      border-radius: 10px;
    }
  }

  .home-intro {
    text-align: center;
    padding: 40px 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
    }

    p {
      font-size: 1.2em;
      color: $black-color;
      line-height: 1.6;
    }
  }

  .home-clients {
    text-align: center;
    padding: 40px 20px;

    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 20px;
    }

    .client-logos {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      img {
        width: 150px;
        height: auto;
        border-radius: 10px;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .home-services {
    padding: 40px 20px;
    background-color: #f9f9f9; // Light background color for the services section
    border-radius: 10px;
    text-align: center;

    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 30px;
    }

    .services-list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      .service {
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 20px;
        max-width: 300px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        img {
          width: 60px;
          height: auto;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 1.5em;
          color: $primary-blue;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.1em;
          color: $black-color;
          line-height: 1.5;
        }
      }
    }
  }

  .home-news {
    padding: 40px 20px;
    text-align: center;

    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 30px;
    }

    .news-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .news-item {
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        h3 {
          font-size: 1.5em;
          color: $primary-blue;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.1em;
          color: $black-color;
          line-height: 1.5;
        }
      }
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .home-intro, .home-clients, .home-services, .home-news {
    padding: 20px;

    h1, h2 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }

    .client-logos {
      img {
        width: 100px;
      }
    }

    .home-services .services-list, .home-news .news-list {
      flex-direction: column;
      align-items: center;

      .service, .news-item {
        max-width: 100%;
      }
    }
  }
}





