@import './var.scss';

.webdev-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;

  .webdev-header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5em;
      color: $primary-blue;
      text-decoration: underline;
    }
  }

  .webdev-content {
    margin-bottom: 40px;

    h2 {
      font-size: 2em;
      color: $primary-blue;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2em;
      color: $black-color;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  .webdev-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .webdev-text {
      flex: 1;
      padding-right: 20px;

      h2 {
        font-size: 2em;
        color: $primary-blue;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        color: $black-color;
        line-height: 1.6;
      }
    }

    .webdev-image {
      flex: 1;
      text-align: right;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }

  .coming-soon-section {
    text-align: center;
    margin-top: 40px;

    h2 {
      font-size: 2.5em;
      color: $primary-blue;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2em;
      color: $black-color;
      line-height: 1.6;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .webdev-section {
    flex-direction: column;

    .webdev-text {
      padding-right: 0;
      text-align: center;
      margin-bottom: 20px;
    }

    .webdev-image {
      text-align: center;
      display: flex;
      justify-content: center;

      img {
        width: 80%;
      }
    }
  }
}
